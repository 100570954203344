import DASHBOARD_BACKEND_URL from '../consts'
import AuthUtil from '../../utils/AuthUtil'

export const DEFAULT_DAYS_BACK = 30
export const DEFAULT_HITS_DAYS_BACK = 10
export async function performPostRequest(navigate, endpoint, request_form_data) {
  const form_data = new FormData()
  for (let key in request_form_data) {
    form_data.append(key, request_form_data[key])
  }
  const resp = await fetch(DASHBOARD_BACKEND_URL + endpoint, {
    method: 'POST',
    headers: {
      Authorization: AuthUtil.GetJWTToken(),
    },
    body: form_data,
  })
  if (resp.status === 401) {
    navigate('/logout?expired=true')
    return {
      status: resp.status,
      data: null,
    }
  }
  if (resp.status !== 200) {
    return {
      status: resp.status,
      data: null,
    }
  }
  const contentType = resp.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return resp.json().then((data) => {
      // The response was a JSON object
      // Process your data as a JavaScript object
      return {
        status: resp.status,
        data: data,
      }
    })
  } else {
    return resp.text().then((text) => {
      // The response wasn't a JSON object
      // Process your text as a String
      return {
        status: resp.status,
        data: text,
      }
    })
  }
}
