import {
  LoginUser,
  RegisterUser,
  RequestNewEmailVerificationLink,
  RequestPasswordResetLink,
  ResetPassword,
  VerifyUserEmail,
} from './api/routes/account_actions/account'
import {
  LOCAL_STORAGE_IS_VERIFIED_ITEM_NAME,
  LOCAL_STORAGE_JOURNEY_WEBSITE_ITEM_NAME,
  LOCAL_STORAGE_JWT_ITEM_NAME,
  LOCAL_STORAGE_SELECTED_WEBPAGE,
  LOCAL_STORAGE_SELECTED_WEBSITE,
} from './consts'

class AuthUtil {
  static GetJWTToken() {
    return localStorage.getItem(LOCAL_STORAGE_JWT_ITEM_NAME) || ''
  }
  static async DoLogin(email, password) {
    let { status, text } = await LoginUser(email, password)
    if (status === 200) {
      localStorage.setItem(LOCAL_STORAGE_JWT_ITEM_NAME, text)
    }
    return { status, text }
  }

  static async DoSignup(email, password) {
    let { status, text } = await RegisterUser(email, password)
    if (status) {
      localStorage.setItem(LOCAL_STORAGE_JWT_ITEM_NAME, text)
    }
    return { status, text }
  }

  static async RequestPasswordReset(email) {
    let { status, text } = await RequestPasswordResetLink(email)
    return { status, text }
  }

  static async RequestEmailVerification(navigate) {
    let { status, text } = await RequestNewEmailVerificationLink(navigate)
    return { status, text }
  }

  static async ResetPassword(token, password) {
    let { status, text } = await ResetPassword(token, password)
    return { status, text }
  }

  static async VerifyEmail(token, email) {
    let { status, text } = await VerifyUserEmail(token, email)
    return { status, text }
  }

  static CleanLocalStorageForLogout() {
    localStorage.removeItem(LOCAL_STORAGE_JWT_ITEM_NAME)
    localStorage.removeItem(LOCAL_STORAGE_SELECTED_WEBSITE)
    localStorage.removeItem(LOCAL_STORAGE_IS_VERIFIED_ITEM_NAME)
    localStorage.removeItem(LOCAL_STORAGE_JOURNEY_WEBSITE_ITEM_NAME)
    localStorage.removeItem(LOCAL_STORAGE_SELECTED_WEBPAGE)
  }
}

export default AuthUtil
