import 'react-app-polyfill/stable'
import 'core-js'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import AuthUtil from './utils/AuthUtil'
import './scss/style.scss'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AppStateVars from './utils/AppStateVarsUtil'
import PasswordReset from './views/pages/forgot/password'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const EmailVerification = React.lazy(() => import('./views/pages/verify/email'))
const ResetPassword = React.lazy(() => import('./views/pages/forgot/password'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const e = document.getElementById('root')
const root = createRoot(e)

const F = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [jwtToken, setjwtToken] = React.useState(AuthUtil.GetJWTToken())
  const isLoggedIn = jwtToken !== ''
  const appStateVars = AppStateVars()
  const [count, setCount] = React.useState(0)
  let requested_url = encodeURIComponent(window.location.pathname + window.location.search)
  // we only allow deep linking to billing and page-overview
  if (
    !isLoggedIn &&
    !(requested_url.includes('billing') || requested_url.includes('page-overview'))
  ) {
    requested_url = ''
  }
  return (
    <Provider store={store}>
      <BrowserRouter basename={'/dashboard'}>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={
                !isLoggedIn ? (
                  <Login jwtToken={jwtToken} setjwtToken={setjwtToken} />
                ) : (
                  <Navigate to="/home" />
                )
              }
            />
            <Route
              exact
              path="/register"
              name="Registration Page"
              element={
                !isLoggedIn ? (
                  <Register jwtToken={jwtToken} setjwtToken={setjwtToken} />
                ) : (
                  <Navigate to="/home" />
                )
              }
            />
            <Route
              exact
              path="/reset-password"
              name="Password Reset Page"
              element={
                !isLoggedIn ? (
                  <PasswordReset jwtToken={jwtToken} setjwtToken={setjwtToken} />
                ) : (
                  <Navigate to="/home" />
                )
              }
            />
            <Route
              exact
              path="/verify-email"
              name="Email Verification Page"
              element={<EmailVerification jwtToken={jwtToken} setjwtToken={setjwtToken} />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              exact
              path="/reset-password"
              name="Reset Password"
              element={!isLoggedIn ? <ResetPassword /> : <Navigate to="/dashboard" />}
            />
            <Route
              path="*"
              name="Home"
              element={
                isLoggedIn ? (
                  <DefaultLayout
                    count={count}
                    setCount={setCount}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    appStateVars={appStateVars}
                    jwtToken={jwtToken}
                    setjwtToken={setjwtToken}
                  />
                ) : (
                  // create a deep link to the page the user was trying to access
                  <>
                    {requested_url ? (
                      <>
                        <Navigate to={'/login?redirect=' + requested_url} />
                      </>
                    ) : (
                      <Navigate to="/login" />
                    )}
                  </>
                )
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
      {/*<App />*/}
    </Provider>
  )
}

root.render(
  <F />,
  // <React.StrictMode>
  //   <F />
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
