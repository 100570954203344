import React, { useRef, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CToaster,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'
import PropTypes from 'prop-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AuthUtil from '../../../utils/AuthUtil'
import authUtil from '../../../utils/AuthUtil'
import { LOCAL_STORAGE_JWT_ITEM_NAME } from '../../../utils/consts'
import { ShowErrorToast, ShowHeroframeMessage } from '../../../utils/SystemMessagesUtil'

const PasswordReset = (props) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const toaster = useRef()
  const [toast, addToast] = useState(<></>)
  const [loading, setLoadingVisible] = React.useState(false)

  let mode = 'email'
  if (searchParams.get('token')) {
    // display the reset password form
    mode = 'reset'
  }
  const DoRequestReset = (event) => {
    //pass
    event.preventDefault()
    let email = event.target[0].value
    if (email.length < 3) {
      ShowErrorToast(addToast, 'Email too short')
      return
    }
    setLoadingVisible(true)
    AuthUtil.RequestPasswordReset(email).then((resp) => {
      setLoadingVisible(false)
      const { status, text } = resp
      if (status === 200) {
        ShowHeroframeMessage(addToast, 'Password reset link sent')
      } else {
        if (text) {
          ShowErrorToast(addToast, 'An error occurred while requesting a password reset. ' + text)
        } else {
          ShowErrorToast(addToast, 'An error occurred while requesting a password reset.')
        }
      }
    })
  }

  const DoResetPassword = (event) => {
    //pass
    event.preventDefault()
    let password = event.target[0].value
    if (password.length < 8) {
      ShowHeroframeMessage(addToast, 'Password too short')
      return
    }
    let token = searchParams.get('token')
    setLoadingVisible(true)
    authUtil.ResetPassword(token, password).then((resp) => {
      setLoadingVisible(false)
      const { status, text } = resp
      if (status === 200) {
        localStorage.setItem(LOCAL_STORAGE_JWT_ITEM_NAME, text)
        props.setjwtToken(text)
        navigate('/home')
      } else {
        if (status === 429) {
          ShowErrorToast(addToast, 'Too many requests. Please try again later.')
        } else {
          if (text) {
            ShowErrorToast(addToast, 'An error occurred while resetting your password. ' + text)
          } else {
            ShowErrorToast(addToast, 'An error occurred while requesting a password reset.')
          }
        }
      }
    })
  }
  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CToaster ref={toaster} push={toast} placement="top-end" />
          <CRow className="justify-content-center">
            <CCol sm={5}>
              <CCard>
                <CCardHeader style={{ textAlign: 'center', height: '92px' }}>
                  <a href={'https://app.heroframe.ai'}>
                    <img
                      src={'https://include.heroframe.ai/core/logo.png'}
                      alt="logo"
                      style={{ width: '65%' }}
                    />
                  </a>
                </CCardHeader>
                <CCardBody>
                  {mode !== 'reset' ? (
                    <>
                      <CForm onSubmit={DoRequestReset}>
                        <h4 style={{ textAlign: 'left', color: '#444' }}>Password Reset</h4>
                        <p style={{ textAlign: 'left' }} className="text-medium-emphasis">
                          Enter your email address to receive a password reset link
                        </p>
                        <div style={{ width: '80%', margin: '0 auto' }}>
                          <CInputGroup className="mb-3">
                            <CInputGroupText>@</CInputGroupText>
                            <CFormInput
                              type={'email'}
                              placeholder="Email"
                              autoComplete="email"
                              disabled={loading}
                              required
                            />
                          </CInputGroup>
                          <div style={{ textAlign: 'center' }}>
                            <CButton
                              disabled={loading}
                              style={{ width: '100%' }}
                              type={'submit'}
                              color="primary"
                            >
                              Request Password Reset {loading && <CSpinner size={'sm'}></CSpinner>}
                            </CButton>
                          </div>
                          <CRow>
                            <br />
                          </CRow>
                        </div>
                      </CForm>
                    </>
                  ) : (
                    <>
                      <CForm onSubmit={DoResetPassword}>
                        <h4 style={{ textAlign: 'left', color: '#444' }}>Password Reset</h4>
                        <p style={{ textAlign: 'left' }} className="text-medium-emphasis">
                          Enter your new password
                        </p>
                        <div style={{ width: '80%', margin: '0 auto' }}>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                              type="password"
                              placeholder="Password"
                              autoComplete="current-password"
                              required
                              disabled={loading}
                            />
                          </CInputGroup>
                          <div style={{ textAlign: 'center' }}>
                            <CButton
                              disabled={loading}
                              style={{ width: '100%' }}
                              type={'submit'}
                              color="primary"
                            >
                              Reset Password {loading && <CSpinner size={'sm'}></CSpinner>}
                            </CButton>
                          </div>
                          <CRow>
                            <br />
                          </CRow>
                        </div>
                      </CForm>
                    </>
                  )}
                </CCardBody>
                <CCardFooter>
                  <small style={{ color: '#444' }}>
                    <a style={{ color: '#444' }} href={'/dashboard/login'}>
                      Go Back
                    </a>
                  </small>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

PasswordReset.propTypes = {
  jwtToken: PropTypes.string,
  setjwtToken: PropTypes.func,
}

export default PasswordReset
