import React from 'react'
import { CToast, CToastBody, CToastClose, CToastHeader } from '@coreui/react'

export function ShowErrorToast(addToast, message) {
  const toast = (
    <CToast autohide={true} visible={true} color="danger" className="text-white align-items-center">
      <div className="d-flex">
        <CToastBody>{message}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )
  addToast(toast)
}

export function ShowLoadingToast(addToast, message) {
  const toast = (
    <CToast
      autohide={true}
      visible={true}
      color="primary"
      className="text-white align-items-center"
    >
      <div className="d-flex">
        <CToastBody>{message}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )
  addToast(toast)
}

export function ShowSuccessToast(addToast, message, closeButton = true) {
  const toast = (
    <CToast
      autohide={true}
      visible={true}
      color="success"
      className="text-white align-items-center"
    >
      <div className="d-flex">
        <CToastBody>{message}</CToastBody>
        {closeButton && <CToastClose className="me-2 m-auto" white />}
      </div>
    </CToast>
  )
  addToast(toast)
}

export function ShowHeroframeMessage(addToast, message, delay = 5000) {
  if (delay > 6000) {
    delay = 6000
  }
  const toast = (
    <CToast autohide={true} delay={delay}>
      <CToastHeader closeButton>
        <img
          src={'https://include.heroframe.ai/core/Heroframe-AI-Icon.png'}
          alt={'Heroframe Logo'}
          width="40"
        />
        <div className="fw-bold me-auto">Heroframe AI</div>
        <small>Just Now</small>
      </CToastHeader>
      <CToastBody>{message}</CToastBody>
    </CToast>
  )
  addToast(toast)
}
