import React from 'react'
import { LOCAL_STORAGE_SELECTED_WEBPAGE, LOCAL_STORAGE_SELECTED_WEBSITE } from './consts'

const AppStateVars = () => {
  const [selectedWebsite, setSelectedWebsite] = React.useState(undefined)
  const [selectedWebPage, setSelectedWebPage] = React.useState(null)
  const [customBreadcrumbText, setCustomBreadcrumbText] = React.useState(null)
  const [timeSelectorShown, showTimeSelectorFunction] = React.useState(true)
  const [searchPageShow, showPageSearchFunction] = React.useState(true)

  return {
    GetSelectedWebsite: () => {
      if (localStorage.getItem(LOCAL_STORAGE_SELECTED_WEBSITE)) {
        return localStorage.getItem(LOCAL_STORAGE_SELECTED_WEBSITE)
      }
      return selectedWebsite
    },
    SetSelectedWebsite: (website) => {
      if (website) {
        localStorage.setItem(LOCAL_STORAGE_SELECTED_WEBSITE, website)
      } else {
        localStorage.removeItem(LOCAL_STORAGE_SELECTED_WEBSITE)
      }
      setSelectedWebsite(website)
      setSelectedWebPage(null)
      setCustomBreadcrumbText(null)
      showTimeSelectorFunction(true)
    },
    GetSelectedWebPage: () => {
      if (localStorage.getItem(LOCAL_STORAGE_SELECTED_WEBPAGE)) {
        return localStorage.getItem(LOCAL_STORAGE_SELECTED_WEBPAGE)
      }
      return selectedWebPage
    },
    GetCustomBreadcrumbText: () => {
      return customBreadcrumbText
    },
    IsTimeSelectorShown: () => {
      return timeSelectorShown
    },
    IsSearchPageShown: () => {
      return searchPageShow
    },
    ChooseWebPageFunction: (event, webPage) => {
      window.scrollTo(0, 0)
      if (event) {
        event.preventDefault()
      }
      // don't navigate to the same page
      if (webPage === selectedWebPage && webPage !== null) {
        return
      }
      if (!webPage) {
        // if the selection was reset
        localStorage.removeItem(LOCAL_STORAGE_SELECTED_WEBPAGE)
        setSelectedWebPage(null)
        setCustomBreadcrumbText(null)
        showTimeSelectorFunction(true)
      } else {
        localStorage.setItem(LOCAL_STORAGE_SELECTED_WEBPAGE, webPage)
        setSelectedWebPage(webPage)
        setCustomBreadcrumbText('Page Overview')
        showTimeSelectorFunction(true)
      }
    },
    ResetView: () => {
      showPageSearchFunction(false)
      showTimeSelectorFunction(true)
      window.scrollTo(0, 0)
      setSelectedWebPage(null)
      setCustomBreadcrumbText(null)
    },
    ShowSearch: () => {
      showPageSearchFunction(true)
    },
    HideTimeSelector: () => {
      showTimeSelectorFunction(false)
    },
    ShowTimeSelector: () => {
      showTimeSelectorFunction(true)
    },
  }
}

export default AppStateVars
