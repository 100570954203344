export const LOCAL_STORAGE_JWT_ITEM_NAME = 'heroframejwtToken'
export const LOCAL_STORAGE_REDIRECT_AFTER = 'heroframeredirectafterlogin'
export const LOCAL_STORAGE_JOURNEY_WEBSITE_ITEM_NAME = 'heroframejourneywebsite'
export const LOCAL_STORAGE_IS_VERIFIED_ITEM_NAME = 'heroframeisverified'
export const LOCAL_STORAGE_SELECTED_WEBSITE = 'heroframeselectedwebsite'

export const LOCAL_STORAGE_SELECTED_WEBPAGE = 'heroframeselectedwebpage'
let api_url
export const LOCAL_API_URL = 'http://127.0.0.1:5000'
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  api_url = LOCAL_API_URL + '/dashboard/api'
} else {
  // production code
  api_url = 'https://app.heroframe.ai/dashboard/api'
}

const DASHBOARD_BACKEND_URL = api_url
export default DASHBOARD_BACKEND_URL
