import { performPostRequest } from '../../api'
import DASHBOARD_BACKEND_URL from '../../../consts'

export async function LoginUser(email, password) {
  const d = new FormData()
  d.append('email', email)
  d.append('password', password)
  const resp = await fetch(DASHBOARD_BACKEND_URL + '/signin', {
    method: 'POST',
    body: d,
  })
  const contentType = resp.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return resp.json().then((data) => {
      // The response was a JSON object
      // Process your data as a JavaScript object
      return {
        status: resp.status,
        text: data['text'],
      }
    })
  } else {
    return resp.text().then((text) => {
      // The response wasn't a JSON object
      // Process your text as a String
      return {
        status: resp.status,
        text: text,
      }
    })
  }
}

export async function RequestPasswordResetLink(email) {
  const d = new FormData()
  d.append('email', email)
  const resp = await fetch(DASHBOARD_BACKEND_URL + '/request-password-reset', {
    method: 'POST',
    body: d,
  })
  const contentType = resp.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return resp.json().then((data) => {
      // The response was a JSON object
      // Process your data as a JavaScript object
      return {
        status: resp.status,
        text: data['text'],
      }
    })
  } else {
    return resp.text().then((text) => {
      // The response wasn't a JSON object
      // Process your text as a String
      return {
        status: resp.status,
        text: text,
      }
    })
  }
}

export async function RequestNewEmailVerificationLink(navigate) {
  return await performPostRequest(navigate, '/request-email_verification', {})
}

export async function VerifyUserEmail(token, email) {
  const d = new FormData()
  d.append('token', token)
  d.append('email', email)
  const resp = await fetch(DASHBOARD_BACKEND_URL + '/verify-email', {
    method: 'POST',
    body: d,
  })
  const contentType = resp.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return resp.json().then((data) => {
      // The response was a JSON object
      // Process your data as a JavaScript object
      return {
        status: resp.status,
        text: data['text'],
      }
    })
  } else {
    return resp.text().then((text) => {
      // The response wasn't a JSON object
      // Process your text as a String
      return {
        status: resp.status,
        text: text,
      }
    })
  }
}

export async function ResetPassword(token, password) {
  const d = new FormData()
  d.append('token', token)
  d.append('password', password)
  const resp = await fetch(DASHBOARD_BACKEND_URL + '/password-reset', {
    method: 'POST',
    body: d,
  })
  const contentType = resp.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return resp.json().then((data) => {
      // The response was a JSON object
      // Process your data as a JavaScript object
      return {
        status: resp.status,
        text: data['text'],
      }
    })
  } else {
    return resp.text().then((text) => {
      // The response wasn't a JSON object
      // Process your text as a String
      return {
        status: resp.status,
        text: text,
      }
    })
  }
}

export async function RegisterUser(email, password, website) {
  const d = new FormData()
  d.append('email', email)
  d.append('password', password)
  const resp = await fetch(DASHBOARD_BACKEND_URL + '/signup', { method: 'POST', body: d })
  return {
    status: resp.status === 200,
    text: (await resp.json())['text'],
  }
}

export default LoginUser

export async function GetUserInfoFromAPI(navigate) {
  return await performPostRequest(navigate, '/get_user_info', {})
}
